import Joi from "joi";
import React, { useState, useEffect } from "react";
import { ApiClient, isApiError } from "../../utils/ApiClient";

import { joiResolver } from "@hookform/resolvers/joi";
import { useForm } from "react-hook-form";
import { passwordValidation, nameValidation } from "../../utils";
import { gaEventTrack } from "../../utils";
import { hotjar } from "react-hotjar";
import { HOTJAR_SETTINGS_ID, HOTJAR_SETTINGS_VERSION } from "../../utils/constants";
import SignUpComplete from "./SignUpComplete";

export default function SignUpForm() {
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPwdShown, setConfirmPwdShown] = useState(false);
  const [aggreementChecked, setAgreementChecked] = useState(false);
  const [emailError, setEmailError] = useState(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  useEffect(() => {
    hotjar.initialize(HOTJAR_SETTINGS_ID, HOTJAR_SETTINGS_VERSION);
  }, []);

  const togglePassword = (setPwdShownfn) => {
    setPwdShownfn((prev) => !prev);
  };

  const registerSchema = Joi.object({
    name: Joi.string().required().custom(nameValidation).messages({
      "string.empty": "Required",
    }),
    organizationName: Joi.string().required().messages({
      "string.empty": "Required",
    }),
    email: Joi.string()
      .required()
      .email({ tlds: { allow: false } })
      .messages({
        "string.email": "Invalid email address",
        "string.empty": "Required",
      }),
    password: Joi.string().min(8).required().custom(passwordValidation).messages({
      "string.empty": "Required",
      "string.min": "Password must be at least {#limit} characters",
    }),
    confirmPwd: Joi.any().equal(Joi.ref("password")).required().label("Confirm password").messages({ "any.only": "Passwords do not match" }),
  });

  const handleAgreeCheckboxChange = (e) => {
    if (e.target.checked) {
      setAgreementChecked(true);
    } else {
      setAgreementChecked(false);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: joiResolver(registerSchema),
  });

  const cancelHandler = (e) => {
    e.preventDefault();
    window.history.go(-1);
  };

  const onSubmit = async (data) => {
    const client = new ApiClient();
    const checkEmailResult = await client.checkEmail(data.email);
    if (checkEmailResult) {
      setEmailError(checkEmailResult);
      return;
    }
    delete data.confirmPwd;
    const result = await client.register(data);
    if (isApiError(result)) {
      console.error(result);
      return;
    }

    sessionStorage.setItem("name", data.name);
    sessionStorage.setItem("email", data.email);
    setFormSubmitted(true);
    hotjar.event("signup button clicked");
    gaEventTrack("signup page", "signup button clicked", "button");
  };
  const handleEmailChange = (e, onChange) => {
    if (emailError) {
      setEmailError(null);
    }
    onChange(e);
  };

  return !formSubmitted ? (
    <div className="sign-up-container">
      <form className="container" onSubmit={handleSubmit(onSubmit)}>
        <div className="head-2">Sign up</div>
        <div className="head-3">First, let’s set up your profile.</div>

        <div className="d-flex flex flex-column sign-up-form">
          <div className="input-wrapper">
            <label className="label-1" for="name">
              Your name
            </label>
            <input
              className="form-control"
              name="name"
              {...register("name", { required: "Name is required" })}
              placeholder="e.g. Katherine Smith or Johnson, William"
            />
            {errors.name && <p className="error-text">{errors.name.message}</p>}
          </div>

          <div className="input-wrapper">
            <label className="label-1" for="organizationName">
              Organisation name
            </label>
            <input
              className="form-control"
              name="organizationName"
              {...register("organizationName", {
                required: "organizationName is required",
              })}
              placeholder="Name of your business or company"
            />
            {errors.organizationName && <p className="error-text">{errors.organizationName.message}</p>}
          </div>

          <div className="input-wrapper">
            <label className="label-1" for="email">
              Email (this will be your login)
            </label>
            <input
              name="email"
              className="form-control"
              type="email"
              {...register("email")}
              onChange={(e) => handleEmailChange(e, register("email").onChange)}
              placeholder="e.g. katherine.smith@gmail.com"
            />
            {errors.email && <p className="error-text">{errors.email.message}</p>}
            {emailError && <p className="error-text">{emailError}</p>}
          </div>

          <div className="input-wrapper">
            <label className="label-1" for="password">
              Password
            </label>
            <div className="password-input">
              <input
                className="form-control"
                name="password"
                type={passwordShown ? "text" : "password"}
                {...register("password")}
                placeholder="min. 8 characters (include at least 1 number and 1 symbol)"
              />
              <a
                className="password-show"
                onClick={(e) => {
                  e.preventDefault();
                  togglePassword(setPasswordShown);
                }}
              >
                {passwordShown ? "Hide" : "Show"}
              </a>
            </div>
            {errors.password && <p className="error-text">{errors.password.message}</p>}
          </div>

          <div className="input-wrapper">
            <label className="label-1" for="confirmPwd">
              Retype password
            </label>
            <div className="password-input">
              <input
                className="form-control"
                name="confirmPwd"
                type={confirmPwdShown ? "text" : "password"}
                {...register("confirmPwd")}
                placeholder="min. 8 characters (include at least 1 number and 1 symbol)"
              />
              <a
                className="password-show"
                onClick={(e) => {
                  e.preventDefault();
                  togglePassword(setConfirmPwdShown);
                }}
              >
                {confirmPwdShown ? "Hide" : "Show"}
              </a>
            </div>
            {errors.confirmPwd && <p className="error-text">{errors.confirmPwd.message}</p>}
          </div>

          <div className="d-flex user-agreement">
            <label class="checkbox-container">
              <input type="checkbox" onChange={handleAgreeCheckboxChange} />
              <span class="checkmark"></span>
            </label>

            <p>
              I have read and agree with the{" "}
              <a href={`/user-agreement`} target="_blank" rel="noopener noreferrer">
                user agreement
              </a>
            </p>
          </div>

          <div id="welcome-next-btn-container" className="d-flex justify-content-end mt-5">
            <button className="default-btn" onClick={cancelHandler}>
              CANCEL
            </button>
            <button className="secondary-btn" type="submit" disabled={!aggreementChecked}>
              SIGN UP
            </button>
          </div>
        </div>
      </form>
    </div>
  ) : (
    <SignUpComplete />
  );
}
