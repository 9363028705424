import { HYFEN8_PORTAL_BACKEND_URL } from "./constants";

export const isApiError = (object) => object && "code" in object && "message" in object;

export const isError = (object) => "name" in object && "message" in object;

function isErrorStatus(status) {
  return status >= 400 && status < 600;
}

const errorToApiError = (error) => {
  let errorResponse;
  if (isError(error)) {
    errorResponse = {
      code: 503,
      message: error.message,
      stack: error.stack,
    };
  }
  errorResponse = {
    code: -1,
    message: "Something went wrong",
  };
  return errorResponse;
};

export class ApiClient {
  basePath;
  constructor() {
    const apiVersion = "v1";
    this.basePath = `${HYFEN8_PORTAL_BACKEND_URL}/${apiVersion}`;
  }

  /**
   * Get base path URL for the API
   * @returns Base path URL
   */
  getBasePath() {
    return this.basePath;
  }

  /**
   * Check whether profile already exists for given email address
   * @param {String} email Email Address
   */
  async checkEmail(email) {
    try {
      const url = new URL(`${this.basePath}/auth/check-email`);
      const params = { email };
      url.search = new URLSearchParams(params).toString();
      const headers = {
        Accept: "application/json",
      };
      const response = await fetch(url.toString(), {
        method: "GET",
        headers: headers,
      });
      const status = response.status;
      if (status === 409) {
        return "Email address is already in use";
      }
      if (status === 400) {
        return "Email address is invalid";
      }
      if (status > 400) {
        return "Please use a different email address";
      }
    } catch (error) {
      return "Please use a different email address";
    }
    return null;
  }

  async register(user) {
    try {
      const url = `${this.basePath}/auth/register`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify(user),
      });
      return await response.json();
    } catch (error) {
      return errorToApiError(error);
    }
  }

  async resendVerificationEmail(email) {
    try {
      const url = `${this.basePath}/auth/resend-verification-email`;

      const headers = {
        "Content-Type": "application/json",
        Accept: "application/json",
      };
      const response = await fetch(url, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({ email }),
      });
      if (isErrorStatus(response.status)) {
        return await response.json();
      }
      return;
    } catch (error) {
      return errorToApiError(error);
    }
  }
}
