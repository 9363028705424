import ReactGA from "react-ga4";
import { GOOGLE_ANALYTICS_TRACK_ID } from "./constants";

export function passwordValidation(value, helpers) {
  if (value.length < 8) {
    return helpers.message("Password must be at least 8 characters");
  }
  if (!value.match(/\d/)) {
    return helpers.message("Password must contain at least 1 number");
  }
  const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  if (!specialChars.test(value)) {
    return helpers.message("Password must contain at least one symbol");
  }

  return value;
}

export function nameValidation(value, helpers) {
  if (value.match(/\d/)) {
    return helpers.message("name must not contain numbers");
  }
  return value;
}

export function gaEventTrack(category, action, label) {
  ReactGA.initialize(GOOGLE_ANALYTICS_TRACK_ID);
  ReactGA.event({
    category: category,
    action: action,
    label: label,
  });
}
