import React from "react";
import Layout from "../components/App/Layout";
import Navbar from "../components/App/Navbar";
import Footer from "../components/App/Footer";
import SignUpForm from "../components/SignUp/SignUpForm";

const Register = () => {
  return (
    <Layout title={"Sign up"}>
      <Navbar />
      <SignUpForm />
      <Footer />
    </Layout>
  );
};

export default Register;
