import React, { useState, useEffect } from "react";

import { ApiClient, isApiError } from "../../utils/ApiClient";
import { gaEventTrack } from "../../utils";
import { hotjar } from "react-hotjar";

export default function SignUpComplete() {
  const [isResending, setIsResending] = useState(false);
  const [isActionSuccessful, setIsActionSuccessful] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setName(sessionStorage.getItem("name"));
      setEmail(sessionStorage.getItem("email"));
    }
  }, []);

  const handleResendClick = async () => {
    setIsActionSuccessful(false);
    setIsResending(true);
    const client = new ApiClient();
    const result = await client.resendVerificationEmail(email);
    if (isApiError(result)) {
      console.error(result);
      setIsResending(false);
      return;
    }
    setIsResending(false);
    setIsActionSuccessful(true);
    hotjar.event("resend verification button clicked");
    gaEventTrack("signup complete page", "resend email verification button clicked", "button");
  };

  const handleBackTo = () => {
    window.location.pathname = "/";
  };
  return (
    <main className="sign-up-container signup-complete">
      <div className="container">
        <h1 className="head-1">
          {name},
          <br />
          just one last step.
        </h1>

        <p className="su-p pt-4">
          To finalise your profile setup we need to verify your email address. Please check your inbox, then follow the instructions sent from the team at
          Hyfen8.
        </p>
        <p className="su-p pt-2 mb-5">Haven't received anything from us? Check your spam folder or click resend verification below.</p>

        <div className="d-flex justify-content-end signup-complete-btns">
          <button className="default-btn" onClick={handleBackTo}>
            BACK TO HYFEN8
          </button>
          <button className="secondary-btn ml-4" onClick={handleResendClick} disabled={isResending}>
            RESEND VERIFICATION
          </button>
        </div>

        {isActionSuccessful && (
          <div style={{ padding: "10px 26px" }} className="text-6">
            Email resent
          </div>
        )}
      </div>
    </main>
  );
}
